import { SvgIcon } from "@mui/material";

function LocationIcon(props: any) {
  return (
    <SvgIcon {...props} sx={{ width: '21px', height: '24px', }} width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#240;&#159;&#166;&#134; icon &#34;location&#34;">
        <g id="Group">
          <path id="Vector" d="M10.4626 14.4148C8.08595 14.4148 6.14453 12.4846 6.14453 10.0968C6.14453 7.70915 8.08595 5.79004 10.4626 5.79004C12.8391 5.79004 14.7806 7.7203 14.7806 10.108C14.7806 12.4957 12.8391 14.4148 10.4626 14.4148ZM10.4626 7.46368C9.01208 7.46368 7.81817 8.64639 7.81817 10.108C7.81817 11.5696 9.00092 12.7523 10.4626 12.7523C11.9242 12.7523 13.1069 11.5696 13.1069 10.108C13.1069 8.64639 11.9131 7.46368 10.4626 7.46368Z" fill={props?.fill ||"#2DD5C4"} />
          <path id="Vector_2" d="M10.4621 24C8.81077 24 7.14827 23.3752 5.85399 22.1367C2.5625 18.9679 -1.07488 13.9135 0.297507 7.89958C1.536 2.44351 6.3003 0 10.4621 0H10.4732C14.635 0 19.3993 2.44351 20.6378 7.91074C21.9991 13.9247 18.3617 18.9679 15.0702 22.1367C13.7759 23.3752 12.1134 24 10.4621 24ZM10.4621 1.67364C7.21522 1.67364 3.04228 3.40307 1.93767 8.26778C0.732654 13.523 4.0353 18.053 7.02554 20.9205C8.95582 22.7838 11.9795 22.7838 13.9098 20.9205C16.8889 18.053 20.1915 13.523 19.0088 8.26778C17.8931 3.40307 13.709 1.67364 10.4621 1.67364Z" fill={props?.fill ||"#2DD5C4"} />
        </g>
      </g>
    </SvgIcon>
  );
}

export default LocationIcon;
