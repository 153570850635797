import _ from "lodash";
import moment from "moment";

export const getSafeValue = (object: any, key: string, defaultValue: any) => {
  const value = _.get(object, key);
  return value == null ? defaultValue : value;
};

export const getFullAddress = (address: any, sortAddress = false, hideZipCode = false) => {
  if (address === false) {
    return false
  }
  let addressComponents
  if (sortAddress === true) {
    if (getSafeValue(address, 'address_title', '') !== '') {
      return address.address_title
    }
    if (hideZipCode === true) {
      addressComponents = [
        getSafeValue(address, 'city', ''),
        getSafeValue(address, 'state_province', '')
      ];
    } else {
      addressComponents = [
        getSafeValue(address, 'city', ''),
        getSafeValue(address, 'state_province', ''),
        getSafeValue(address, 'zipcode', '')
      ];
    }
  } else {
    addressComponents = [
      getSafeValue(address, 'street_address', ''),
      getSafeValue(address, 'second_line', ''),
      getSafeValue(address, 'city', ''),
      getSafeValue(address, 'state_province', ''),
      getSafeValue(address, 'country', ''),
      getSafeValue(address, 'zipcode', '')
    ];
  }

  const stringAddress = capitalizeFirstLetter(addressComponents.filter(component => component !== '').join(', '))
  return stringAddress === '' ? 'Workshop' : stringAddress;
};

export const capitalizeFirstLetter = (sentence: string) => {
  if (!sentence) return sentence;
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export const checkIncompleteAddress = (address: any) => {
  const requiredKeys = [
    'street_address',
    'state_province',
    'city',
    'country',
    'zipcode'
  ];

  // Check if all required keys have a valid value
  for (let key of requiredKeys) {
    if (getSafeValue(address, key, '') === '') {
      return false;
    }
  }

  return true;
};

export const isOnlyCityPresent = (address: any) => {
  // Check if city is present and all other fields are empty, null, or undefined
  return getSafeValue(address, 'city', '') && !getSafeValue(address, 'street_address', '') && !getSafeValue(address, 'state_province', '') && !getSafeValue(address, 'country', '') && !getSafeValue(address, 'zipcode', '');
}

export const formatPhoneNumber = (number: any) => {
  if (!number) {
    return number
  }
  // Convert the number to a string to easily manipulate it
  let numStr = number.toString();

  // Strip any non-digit characters
  let numDigits = numStr.replace(/\D/g, '');

  // Check if the number has at least 10 digits
  // if (numDigits.length < 10) {
  //     throw new Error('The number must contain at least 10 digits.');
  // }

  // Format the number according to the US phone number format
  let formattedNumber = `(${numDigits.slice(0, 3)}) ${numDigits.slice(3, 6)}-${numDigits.slice(6, numDigits.length)}`;
  // Output: (123) 456-7890
  return formattedNumber;
}

export const getDuration = (start: any, end: any) => {
  // Parse dates
  let startMoment = moment(start, 'YYYY-MM-DD HH:mm:ss.SS Z');
  let endMoment = moment(end, 'YYYY-MM-DD HH:mm:ss.SS Z');

  // Calculate difference in milliseconds
  let diffMilliseconds = endMoment.diff(startMoment);

  // Convert to duration
  let duration = moment.duration(diffMilliseconds);

  // Extract hours
  return duration.asMinutes() > 0 ? duration.asMinutes() : 30;
}

export const formatTime = (dateTime: any) => {
  const now = moment();
  const target = moment(dateTime);

  if (target.isSame(now, 'day')) {
    return `Today @${target.format('h:mmA')}`;
  } else if (target.isSame(now.add(1, 'day'), 'day')) {
    return `Tomorrow @${target.format('h:mmA')}`;
  } else {
    return target.format('DD.MM.YY @h:mmA');
  }
}

export const getColorVinLicense = (color: any, vin: any, license: any) => {
  const parts = [];

  if (color) parts.push(color);
  if (vin) parts.push(vin);
  if (license) parts.push(license);

  return parts.length > 0 ? `(${parts.join(', ')})` : '';
}