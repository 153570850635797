import { SvgIcon } from "@mui/material";

function MailIcon(props: any) {
  return (
    <SvgIcon {...props} sx={{ width: '24px', height: '21px', }} width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#240;&#159;&#166;&#134; icon &#34;sms&#34;">
        <g id="Group">
          <path id="Vector" fill="transparent" d="M17.2353 19.4H6.41176C3.16471 19.4 1 17.7765 1 13.9882V6.41176C1 2.62353 3.16471 1 6.41176 1H17.2353C20.4824 1 22.6471 2.62353 22.6471 6.41176V13.9882C22.6471 17.7765 20.4824 19.4 17.2353 19.4Z" stroke={props?.fill ||"#2DD5C4"} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path id="Vector_2" fill="transparent" d="M17.2356 6.95215L13.8479 9.65803C12.7331 10.5456 10.9039 10.5456 9.78905 9.65803L6.41211 6.95215" stroke={props?.fill ||"#2DD5C4"} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </g>
    </SvgIcon>
  );
}

export default MailIcon;
