import { SvgIcon } from "@mui/material";

function UserIcon(props: any) {
  return (
    <SvgIcon {...props} sx={{ width: '19px', height: '27px', }} width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Vector" fill="transparent" d="M9.49099 12.055C9.36635 12.0425 9.21679 12.0425 9.0797 12.055C6.11338 11.9553 3.75781 9.52491 3.75781 6.53371C3.75781 3.4802 6.22555 1 9.29157 1C12.3451 1 14.8253 3.4802 14.8253 6.53371C14.8128 9.52491 12.4573 11.9553 9.49099 12.055Z" stroke={props?.fill ||"#2DD5C4"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path id="Vector_2" fill="transparent" d="M3.26209 16.652C0.245969 18.6711 0.245969 21.9614 3.26209 23.968C6.6895 26.2612 12.3105 26.2612 15.7379 23.968C18.754 21.9489 18.754 18.6586 15.7379 16.652C12.323 14.3712 6.70197 14.3712 3.26209 16.652Z" stroke={props?.fill ||"#2DD5C4"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </SvgIcon>
  );
}

export default UserIcon;
