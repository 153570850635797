import { Box, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import LeadProfileApi from "api/resources/leadProfile";
import Edit from "assets/icon/edit_icon_square.svg";
import { httpService } from "helper/httpService";
import { useEffect, useState } from "react";
import { LuSave } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import { refreshRequest } from "redux/reducers/leadProfileReducer";
import { formatPhoneNumber, getFullAddress, getSafeValue } from "utils";
import Style from "./Aboutus.module.scss";
import UserIcon from "components/LeadProfile/SideBar/Icons/User";
import CallIcon from "components/LeadProfile/SideBar/Icons/Call";
import MailIcon from "components/LeadProfile/SideBar/Icons/Mail";
import LocationIcon from "components/LeadProfile/SideBar/Icons/Location";
import Editor from 'react-simple-wysiwyg';
import { LinkButton, PrimaryButton } from "components/Ui/Button/Button";
import { themeColor } from "theme/color";

function AboutUs({ isPublic, isPreview, color, businessInformation }: any) {
  const data = useAppSelector((state: any) => state.leadProfile.data);
  const publicData = useAppSelector((state) => state.leadProfile.publicData);
  const about_us_message = getSafeValue(data, 'about_us_message', "At [Your Company Name], we're more than just a window tinting service. With a dedication to precision and a passion for quality, we've been transforming vehicle aesthetics and enhancing driver comfort for over a decade. Our expert team utilizes state-of-the-art materials and cutting-edge techniques to ensure flawless results every time. Whether you're looking to protect your interiors, boost privacy, or simply elevate your ride's  style, trust in us to bring your vision to life.")

  const [aboutUsMessage, setAboutUsMessage] = useState<any>()
  useEffect(() => {
    setAboutUsMessage(getSafeValue(data, 'about_us_message', "At [Your Company Name], we're more than just a window tinting service. With a dedication to precision and a passion for quality, we've been transforming vehicle aesthetics and enhancing driver comfort for over a decade. Our expert team utilizes state-of-the-art materials and cutting-edge techniques to ensure flawless results every time. Whether you're looking to protect your interiors, boost privacy, or simply elevate your ride's  style, trust in us to bring your vision to life."))
  }, [])

  const onAboutUsMessageChange = (e: any) => {
    setAboutUsMessage(e.target.value)
  }

  const select = useAppSelector((state: any) => state.leadProfile.select);
  const [isAboutUsEdit, setIsAboutUsEdit] = useState(false)

  const handleSave = () => {
    if (isAboutUsEdit) {
      const para = { about_us_message: aboutUsMessage };
      httpService(() => LeadProfileApi.updateProfile(select, para), refreshRequest);
    }
    setIsAboutUsEdit(!isAboutUsEdit);
  };

  const profileData = (icon_key: any, title: any, value: any, color: any = '#818181') => (
    <Box className={Style.mainBox} display={'flex'} gap={'5px'} alignItems={'center'} mb={'10px'}>
      <Box width={'30px'}>
        {icon_key === 'user' && <UserIcon fill={color} />}
        {icon_key === 'call' && <CallIcon fill={color} />}
        {icon_key === 'mail' && <MailIcon fill={color} />}
        {icon_key === 'location' && <LocationIcon fill={color} />}
      </Box>
      <Box className={Style.textBox}>
        <Typography variant="inputText" fontWeight={'600'} component={'p'} fontStyle={isPublic ? 'initial' : 'italic'}>{value}</Typography>
      </Box>
    </Box>
  )

  return (
    <Box>
      <Box mt={2} className={Style.container}>
        <Typography variant='heading4' fontWeight={'600'} component={'h5'}>Get in Touch</Typography>
        <Box className={Style.box} mt={'15px'}>
          <Box className={`cursor`} onClick={() => window.open(`tel:${businessInformation?.contact_number}`, '_self')}>{profileData('call', 'Phone', formatPhoneNumber(getSafeValue(businessInformation, 'contact_number', '---')), color)}</Box>
          {businessInformation?.hide_email_from_profile === false && profileData('mail', 'Email', getSafeValue(businessInformation, 'email', '---'), color)}
          {businessInformation?.hide_address_from_profile === false && businessInformation.address && profileData('location', 'Address', getFullAddress(businessInformation.address), color)}
        </Box>
      </Box>
      <Box mt={2} className={Style.container}>
        <Typography variant='heading4' fontWeight={'600'} component={'h5'} display={'flex'} gap={'15px'} alignItems={'center'} mb={'15px'}>
          About Us
          {isPublic !== true && isAboutUsEdit !== true ? <img src={Edit} alt="edit" className="cursor" onClick={() => setIsAboutUsEdit(true)} /> : ''}
        </Typography>
        {isAboutUsEdit === true ? <>
          <Editor value={aboutUsMessage} onChange={onAboutUsMessageChange} />
          <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} gap={'15px'} mt={'10px'}>
            <Typography
              variant="heading6"
              color={themeColor.link}
              fontWeight={600}
              className="cursor"
              onClick={() => setIsAboutUsEdit(false)}
              mb={'0'}
            >
              Cancel
            </Typography>
            <PrimaryButton type="button" onClick={handleSave}>Save Changes</PrimaryButton>
          </Box>
        </> : <Typography variant='bodyTextNormal' fontWeight={'500'} component={'p'} dangerouslySetInnerHTML={{ __html: aboutUsMessage }} />}
      </Box>
    </Box>
  );
}

export default AboutUs;
